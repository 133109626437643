<div>
  <div class="middle-holder">
    <div class="middle">
      <spb-logo [onlyLogoLink]="true" [routePath]="[mainPath]"></spb-logo>
      <div *ngIf="(configService.logInState$ | async) as user" class="middle">
        <div class="menu">
          <div *ngIf="user.isValidUser" [routerLink]="[mainPath]">Rådgivning
            <span *ngIf="scenario"> - {{ scenario }}</span>
          </div>
          <button *ngIf="user.isValidUser" [routerLink]="[customerPath]" color="primary" mat-raised-button>Pågående Rådgivningar
          </button>
          <button (click)="reset()"
                  *ngIf="user.isValidUser"
                  color="primary"
                  mat-raised-button>Ny kund
          </button>
          <button (click)="dataService.save().subscribe()"
                  *ngIf="user.isValidUser"
                  [disabled]="((controlService.locked$ | async) === true) ||
                  ((controlService.canSave$ | async) !== true)"
                  color="primary"
                  mat-raised-button>Spara
          </button>
        </div>
        <spb-test *ngIf="user.isValidUser && testService.showTestData"></spb-test>
        <div class="spb-filler"></div>
        <div>
          <span>{{ user.name }}</span>
        </div>
        <div class="middle-menu">
          <div class="menu"></div>
          <div class="filler"></div>
          <button [matMenuTriggerFor]="menu" mat-button>
            <span class="material-symbols-outlined">menu</span>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="configService.logout()" mat-menu-item>Logga ut</button>
            <button (click)="testService.showTestData = !testService.showTestData" mat-menu-item>
              <span *ngIf="!testService.showTestData" class="material-symbols-outlined">visibility</span>
              <span *ngIf="testService.showTestData" class="material-symbols-outlined">visibility_off</span>
              Test
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>
