// Export all the things for consumers
export * from './types';
import { defaultTaxInput, taxConfig, tabeller, skattesatser } from './types';
const checkColumn = (age, occupation, taxYear) => {
  // Column 2 is normal age retired people
  if (age > taxConfig[taxYear].retiredAge && occupation === 1) {
    return 2;
  }
  // Column 3 is possibly retired people still working
  if (age > taxConfig[taxYear].retiredAge && occupation === 0) {
    return 3;
  }
  // Column 4 is for ill people that we do not lend to.
  // Column 5 is for unemployed and others
  // Column 6 is retired before "normal" retirement age
  if (age < taxConfig[taxYear].retiredAge && occupation === 1) {
    return 6;
  }
  // Column 1 is standard, employee (0)
  return 1;
};
/**
 * Check the actual tax percentage in your kommun, convert to a table.
 * Note the rounding that is non-Javascript
 *
 * @param county - Is a string like '01' it represents Län, id est 01 is Stockholm 12 is Skåne
 * @param municipality - Is the kommunkod in the Län, so Svalöv is 1214 (12 for Skåne, 14 för Svalöv)
 * @param defaultTable - Use this as default if no county or municipality
 * @param taxYear - The year for which we look
 */
const getTaxTableNumber = (county, municipality, defaultTable, taxYear) => {
  const taxRates = skattesatser[taxYear];
  const key = `${county}${municipality}`;
  const taxPercentage = taxRates[key] || defaultTable;
  // .5 should be rounded down, which is not normal in Javascript.
  return taxPercentage % 1 > 0.5 ? Math.ceil(taxPercentage) : Math.floor(taxPercentage);
};
export const calculateTax = taxInput => {
  // Join the input with the defaults to one object...
  const input = {
    ...defaultTaxInput,
    ...taxInput
  };
  // ...so that we can spread the input into variables and have defaults
  const {
    occupation,
    county,
    municipality,
    table,
    selectTableAfterAgeReduction,
    taxYear,
    almostRetiredThreshold,
    age
  } = input;
  // But income needs to be let ...
  let {
    income
  } = input;
  /**
   * Pick a year
   */
  const taxTables = tabeller[taxYear];
  // Reduce income if age >= 62 and occupation === 0
  const incomeAfterAgeReduction = occupation === 0 && age >= almostRetiredThreshold ? Math.floor(income * 0.7) : undefined;
  const tabell = getTaxTableNumber(county, municipality, table, taxYear);
  const taxColumn = checkColumn(age, occupation, taxYear);
  // In the right table (29,30 .. 42) there is a list of incomes
  // Pick the first one that is greater than the income.
  const lookupValue = tabell;
  // Default value is the first row in taxTables
  let row = taxTables[lookupValue][1];
  if (selectTableAfterAgeReduction && incomeAfterAgeReduction) {
    income = incomeAfterAgeReduction;
  }
  //Find correct row and break when we found it
  for (const rowItem of Object.keys(taxTables[lookupValue])) {
    // Even  if we have numbers as keys they will come out as numbers
    const key = Number.parseInt(rowItem);
    if (key <= income) {
      row = taxTables[lookupValue][key];
    } else {
      break;
    }
  }
  /**
   * Remember that we have one column more
   * than actual columns and that columns at
   * Skatteverket counts 1..7 but we have an
   * array starting at 0
   */
  const taxValueColumn = taxColumn - 1;
  let calcTax = row[taxValueColumn];
  calcTax = row.length === taxConfig[taxYear].columns ? calcTax * (incomeAfterAgeReduction || income) / 100 : calcTax;
  return {
    taxValue: tabell,
    tax: calcTax,
    taxColumn,
    taxPercent: row && row.length === taxConfig[taxYear].columns ? row[taxValueColumn] : undefined,
    income: incomeAfterAgeReduction || income,
    age,
    incomeAfterAgeReduction,
    table: tabell
  };
};
