import { Skattesatser2024, Tabeller2024 } from './definitions';
/**
 * These are all valid years and this object
 * must be updated when we add a new year.
 */
export const taxConfig = {
  2024: {
    columns: 7,
    retiredAge: 67 //66 years by start of this year
  }
};
/**
 * A simple default to use if values are missing.
 */
export const defaultTaxInput = {
  age: 50,
  almostRetiredThreshold: 62,
  occupation: 0,
  income: 0,
  county: '',
  municipality: '',
  table: 33,
  selectTableAfterAgeReduction: false,
  taxYear: 2024
};
/**
 * Just a list of the Skattesatser, keep last year for
 * test and upgrade reasons.
 */
export const skattesatser = {
  2024: Skattesatser2024
};
/**
 * The available tables. Should be limited to this year,
 * and previous. The only reason for two is for easy upgrading
 * and testing
 */
export const tabeller = {
  2024: Tabeller2024
};
